@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@1,500&family=Roboto+Mono&family=Rubik+Dirt&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

._2qp0Z{
  right: initial !important;
  left: 10px !important;
  bottom: 15px !important;
}
._1yCVn{
  right: initial !important;
  left: 15px !important;
}

@layer components {
  .card {
    border-radius: 150px 0px 150px 0px;
  }
  .box {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .box {
      width: 540px;
    }
  }
  @media (min-width: 768px) {
    .box {
      width: 720px;
    }
  }
  @media (min-width: 958px) {
    .box {
      width: 880px;
    }
  }
  @media (min-width: 992px) {
    .box {
      width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .box {
      width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .box {
      width: 1320px;
    }
  }
  .section {
    padding: 80px 0px;
  }
  @media (max-width: 1000px) {
    .section {
      padding: 60px 0px;
    }
  }
  .fw-400 {
    font-weight: 400;
  }
  .fw-500 {
    font-weight: 500;
  }
  .fw-600 {
    font-weight: 600;
  }
  .fw-700 {
    font-weight: 700;
  }
  .fw-800 {
    font-weight: 800;
  }
  .fw-900 {
    font-weight: 900;
  }
  .fs-400 {
    font-size: 14px;
  }
  .fs-300 {
    font-size: 13px;
  }
  .fs-500 {
    font-size: 15px;
  }
  .fs-600 {
    font-size: 16px;
  }
  .fs-700 {
    font-size: 17px;
  }
  .fs-800 {
    font-size: 18px;
  }
  .btn {
    border-radius: 10px;
    padding: 10px 30px;
  }
  .btn-default {
    border: 1px solid gray;
  }
  .shades {
    box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
  }
  .shades:hover {
    box-shadow: rgba(224, 224, 224, 0.4) 0px 11px 38px,
      rgba(0, 0, 0, 0.22) 0px 11px 12px;
  }
  .bg-client {
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 35%,
      rgba(0, 212, 255, 1) 100%
    );
  }
  .bg-service {
    background: rgb(34, 193, 195);
    background: linear-gradient(
      0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(253, 187, 45, 1) 100%
    );
  }
  .bg-product {
    background: rgb(195, 34, 156);
    background: linear-gradient(
      0deg,
      rgba(195, 34, 156, 1) 0%,
      rgba(149, 45, 253, 1) 100%
    );
  }
  .logo-fix {
    position: fixed;
    top: 30px;
    left: 27%;
  }
  .circle {
    border-radius: 50%;
  }
  .center-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tags {
    left: 12%;
  }
  .mygrid {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
  }
  .bg-primary {
    background-color: #3f79ad;
  }
  .bg-primary-op {
    background-color: rgba(63, 121, 173, 0.1);
  }
  .text-primary {
    color: #3f79ad;
  }
  .text-secondary {
    color: #ec8b20;
  }
  .bg-secondary {
    background: #ec8b20;
  }
  .bg-secondary-op {
    background: rgba(236, 139, 32, 0.1);
  }
  .bg-light {
    background: #f6fafd;
  }
  .bg-ash {
    background: #f6fafdf2;
  }
  .bg-tool {
    background: rgba(234, 243, 251, 1);
  }
  .bg-tertiary {
    background: #2c2358;
  }
  .slider-bg {
    background: rgba(151, 171, 194, 0.9);
    border: 1px solid rgba(236, 139, 32, 1);
  }
  .bg-input {
    background-color: rgba(240, 240, 255, 0.8);
  }
  .bg-video {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .border-primary {
    border: 2px solid #3f79ad;
    box-shadow: 4px 4px 0px rgb(63, 121, 173);
  }
  .border-pri {
    border: 1px solid #3f79ad;
  }
  .border-secondary {
    border: 2px solid #ec8b20;
    box-shadow: 4px 4px 0px rgb(236, 139, 32);
  }
  .home-bg {
    background: #f5f5f5;
  }
  .grid-4 {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
  }
  .menu-btn {
    left: 33%;
  }
  .btn-primary {
    background: #3f79ad;
    color: #fff;
    padding: 10px 15px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
  }
  .modal-bg {
    background: rgba(0, 0, 0, 0.6);
  }
  .modal-cancel {
    left: 80%;
    color: #fff;
  }
  .grid-83 {
    display: grid;
    grid-template-columns: 64% 33%;
    justify-content: space-between;
  }
  .grid-74 {
    display: grid;
    grid-template-columns: 57% 40%;
    justify-content: space-between;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: 46% 46%;
  }
  .grid-23 {
    display: grid;
    grid-template-columns: 30% 30%;
  }
  .grid-3 {
    display: grid;
    grid-template-columns: 30% 30% 30%;
  }
  .grid-3s {
    display: grid;
    grid-template-columns: 28% 28% 28%;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: 47% 47%;
  }
  .grid-2s {
    display: grid;
    grid-template-columns: 45% 45%;
  }
  .w-45 {
    width: 45%;
  }
  .head-grid {
    display: grid;
    grid-template-columns: 15% 85%;
    justify-content: space-between;
  }
  .sidebar-w {
    width: 15%;
  }
  .main-p {
    width: 85%;
    float: right;
  }
  .light-bg {
    background: #eaf5ff;
    border-right: 2px solid #3f79ad;
  }
  .text-xxl {
    font-size: 26px;
  }
  .pt-21 {
    padding-top: 81px;
  }
  .w-200 {
    width: 200%;
  }
  .w-150 {
    width: 150%;
  }
  .h-blog {
    height: 480px;
  }
  .h-video {
    height: 550px;
  }
  @media (min-width: 1600px) {
    .h-video {
      height: 650px;
    }
  }
  @media (max-width: 996px) {
    .h-video {
      height: 300px;
    }
  }
  @media (max-width: 1285px) and (min-width: 1140px) {
    .hero-head {
      width: 70% !important;
    }
  }
  .profile-drop {
    left: -100px;
  }
  .hero1 {
    top: 10%;
    left: 60%;
    opacity: 0;
    animation: hero1 8s ease 4s infinite alternate;
  }
  @keyframes hero1 {
    0% {
      opacity: 0;
      scale: 0;
    }
    20% {
      opacity: 1;
      scale: 1;
    }
    60% {
      opacity: 1;
      scale: 1;
    }
    100% {
      opacity: 0;
      scale: 0;
    }
  }
  .hero2 {
    top: 26%;
    left: 20%;
    opacity: 0;
    animation: hero1 8s ease 5s infinite alternate;
  }
  .hero3 {
    top: 55%;
    left: 60%;
    opacity: 0;
    animation: hero1 8s ease 5.5s infinite alternate;
  }
  .hero4 {
    top: 73%;
    left: 20%;
    opacity: 0;
    animation: hero1 8s ease 6s infinite alternate;
  }
  .bg-excel {
    background: linear-gradient(75.12deg, #3f79ad 2%, #6392bd 100%);
  }
  .bg-image {
    background: linear-gradient(255.49deg, #feb019 4.65%, #ec8b20 97.9%);
  }
  .bg-op {
    background-color: rgba(131, 131, 131, 0.7);
  }
  .bg-op-white {
    background-color: rgba(245, 245, 245, 0.8);
  }
  .opps {
    text-shadow: 5px 5px 10px rgb(173, 173, 173);
  }
  .bg-opps {
    background-color: rgb(252, 252, 255);
  }
  .scale-ani {
    position: relative;
    animation: pop 0.3s ease-in-out;
  }
  @keyframes pop {
    from {
      scale: 0;
    }
    to {
      scale: 1;
    }
  }
  .menu {
    position: absolute;
    animation: menu 0.3s ease-in-out;
  }
  @keyframes menu {
    from {
      left: -400px;
    }
    to {
      left: 0px;
    }
  }

  .x-center {
    left: 37%;
  }
  @media (max-width: 996px) {
    .x-center {
      left: 44%;
    }
  }
  @media (max-width: 432px) {
    .x-center {
      left: 40%;
    }
  }
  @media (min-width: 433px) and (max-width: 762px) {
    .x-center {
      left: 42%;
    }
  }
  .left-50 {
    left: 46%;
  }
  .left-45 {
    left: 43%;
  }
  .left-40 {
    left: 40%;
  }
  .top-50 {
    top: 43%;
  }
  .rounded-xxl {
    border-radius: 0px 50px 50px 0px;
  }
  .radius-l {
    border-radius: 60px 0px 60px 0px;
  }
  .z-01 {
    z-index: 3;
  }
  .bg-100 {
    background-size: 100% 100%;
  }
  .bg-blur {
    filter: blur(5px);
  }
  .bap-hero {
    border-left: 5px solid #3f79ad;
    border-bottom: 5px solid #3f79ad;
    border-radius: 20px;
  }
  .h-800 {
    height: 80vh;
  }
  .h-700 {
    height: 70vh;
  }
  .h-600 {
    height: 60vh;
  }
  .service-box {
    height: 500px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 0px 0px 50px 0px;
  }
  .o-process {
    left: 0%;
  }
  .p-process {
    left: 35%;
  }
  .s-process {
    left: 68%;
  }
  .d-process {
    right: -6%;
  }
  @media (max-width: 996px) {
    .s-process {
      left: 65%;
    }
    .d-process {
      right: -8%;
    }
  }
  .ar-32 {
    aspect-ratio: 3/ 2;
  }
}
.btn-danger {
  background: red;
  border-radius: 10px;
  color: white;
}
.sub-menu {
  left: 15.5%;
  top: 29px;
}

.loading-animation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapper {
  display: flex;
  column-gap: 10px;
}
.wrapper .dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #007aff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: animate 0.5s ease-in-out infinite alternate;
}
.dot:nth-child(1) {
  animation-delay: -0.25s;
}
.dot:nth-child(2) {
  background: #e1e1e1;
  animation-delay: -0.5s;
}
.dot:nth-child(3) {
  background: #ec8b20;
  animation-delay: -0.75s;
}
.dot:nth-child(4) {
  animation-delay: -1s;
}

@keyframes animate {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(5px);
  }
}

/* new addition */
.sphere {
  width: 400px;
  height: 400px;
  background: url(https://1.bp.blogspot.com/-UUXaK5GCj-k/UcsKJRMgkVI/AAAAAAAACfM/sePP_H08JTQ/s1600/1.jpg);
  border-radius: 100%;
  background-size: 980px;
  animation: spin 20s infinite linear;
  position: absolute;
  top: 90px;
  left: 180px;
}
@keyframes spin {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 980px;
  }
}
.dots {
  animation: spins 20s infinite linear;
  position: relative;
  top: 55px;
}
.dots2 {
  animation: spins 20s infinite 4s linear;
  position: relative;
  opacity: 0;
}
.dots3 {
  animation: spins 20s infinite 6s linear;
  position: relative;
  opacity: 0;
}
.dots4 {
  animation: spins 20s infinite 8s linear;
  position: relative;
  top: 40px;
  opacity: 0;
}
.dots5 {
  animation: spins 20s infinite 13s linear;
  position: relative;
  top: 20px;
  opacity: 0;
}
.dots6 {
  animation: spins 20s infinite 12s linear;
  position: relative;
  top: 50px;
  opacity: 0;
}
@keyframes spins {
  0% {
    left: 100px;
    scale: 1;
    opacity: 1;
  }
  100% {
    left: 980px;
    opacity: 1;
  }
}
.dash1 {
  width: 580px;
  height: 580px;
  stroke-dasharray: 900;
  stroke-dashoffset: 507;
  animation: draw 10s linear infinite alternate-reverse;
}
.moving-stripes {
  left: 60px;
  top: -10px;
}
@keyframes draw {
  from {
    stroke-dashoffset: 4000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@media (max-width: 1200px) and (min-width: 960px) {
  .extra3 {
    display: none;
  }
}
@media (max-width: 1433px) and (min-width: 1017px) {
  .moving-stripes {
    left: 10px;
    top: -10px;
  }
  .sphere {
    top: 90px;
    left: 120px;
    width: 320px;
    height: 320px;
  }
  .dash1 {
    width: 500px;
    height: 500px;
  }
}
@media (max-width: 1017px) and (min-width: 960px) {
  .moving-stripes {
    left: -20px;
    top: -10px;
  }
  .sphere {
    top: 90px;
    left: 90px;
    width: 320px;
    height: 320px;
  }
  .dash1 {
    width: 480px;
    height: 480px;
  }
}
@media (max-width: 960px) and (min-width: 560px) {
  .ipad-view {
    height: 513px;
  }
  .moving-stripes {
    left: 10px;
    top: -10px;
  }
  .sphere {
    top: 90px;
    left: 120px;
    width: 370px;
    height: 370px;
  }
  .dash1 {
    width: 540px;
    height: 540px;
  }
}
@media (max-width: 560px) and (min-width: 360px) {
  .moving-stripes {
    left: -10px;
    top: -10px;
  }
  .sphere {
    top: 58px;
    left: 67px;
    width: 250px;
    height: 250px;
  }
  .dash1 {
    width: 360px;
    height: 360px;
  }
}
@media (max-width: 360px) {
  .ipad-view {
    height: 273px;
  }
  .moving-stripes {
    left: -10px;
    top: -10px;
  }
  .sphere {
    top: 50px;
    left: 55px;
    width: 180px;
    height: 180px;
  }
  .dash1 {
    width: 280px;
    height: 280px;
  }
}
.for-product {
  background-color: rgba(63, 121, 173, 0.2);
}
.for-service {
  background-color: rgba(236, 139, 32, 0.2);
}
.strokes {
  text-shadow: 2px 2px 0px #ec8b20; /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
}
.strokeme {
  text-shadow: 2px 2px 0px rgba(63, 121, 173); /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
}
.product-imgs {
  left: 10%;
  bottom: 7.7%;
}
.service-imgs {
  bottom: 7.5%;
  left: 20%;
}
@media (max-width: 960px) {
  .product-imgs {
    left: 10%;
    bottom: 4.5%;
  }
  .service-imgs {
    bottom: 5.7%;
    left: 20%;
  }
}
@media (max-width: 500px) {
  .product-imgs {
    left: 2%;
    bottom: -5px;
  }
  .service-imgs {
    bottom: -6px;
    left: 8%;
  }
  input.upndown::-webkit-outer-spin-button,
  input.upndown::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"].upndown {
    -moz-appearance: textfield;
  }
  .bg-socials {
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.9),
        rgba(0, 0, 0, 0.4)
      ),
      url("https://res.cloudinary.com/greenmouse-tech/image/upload/v1726840692/BOG/socials_h4qr0e.jpg");
      background-size: cover;
  }
}
